export const gtc = [
  {
    title: "OVERVIEW",
    text: `This website is operated by Cohackers. On this site, the terms "we", "us" and "our" refer to Cohackers. Cohackers offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.\n
    By visiting this site and/or purchasing any of our products, you are engaging in our "Service" and agreeing to be bound by the following terms and conditions ("Terms and Conditions", "Terms and Conditions"), including any additional terms, conditions and policies referenced and/or hyperlinked herein. These Terms and Conditions apply to all users of this site, including, but not limited to, users who browse the site, are sellers, customers, merchants, and/or content contributors.\n
    Please read these Terms and Conditions of Sale and Use carefully before accessing or using our website. By accessing or using any part of this site, you agree to be bound by these Terms and Conditions. If you do not agree to all of the terms and conditions of this agreement, then you should not access the website or use the services offered on it. If these Terms and Conditions of Sale and Use are considered an offer, acceptance is expressly limited to these Terms and Conditions of Sale and Use.\n
    Any new features and tools that are added to this store in the future will also be subject to these Terms and Conditions of Sale and Use. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms and Conditions of Sale and Use by posting updates and/or changes on our website. It is your responsibility to check this page regularly for changes. . Your continued use of or access to the website following the posting of any changes constitutes your acceptance of those changes.`
  },
  {
    title: "ARTICLE 1 - CONDITIONS OF USE OF OUR WEBSITE",
    text: `By accepting these Terms and Conditions of Sale and Use, you represent that you have reached the age of majority in your country, state or province of residence, and that you have given us your consent to allow any minor in your care to use this website.\n
    The use of our products for any illegal or unauthorized purpose is prohibited, nor shall you, in using the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).\n
    You shall not transmit any worms, viruses or other code of a destructive nature.\n
    Any violation or breach of these Terms and Conditions will result in immediate termination of your Services.\n`
  },
  {
    title: "ARTICLE 2 - GENERAL CONDITIONS",
    text: `We reserve the right to deny access to the Services to any person at any time for any reason.\n
    You understand that your content (excluding your credit card information) may be transferred in an unencrypted manner, and this includes (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements for connecting networks or devices. Credit card information is always encrypted during transmission over networks.\n
    You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service or use of the Service, or any access to the Service or contact on the website through which the Service is provided, without our prior express written permission.\n
    The headings used in this agreement are included for your convenience, and will not limit or affect these Terms.\n`
  },
  {
    title: "ARTICLE 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION",
    text: `We are not responsible for the accuracy, completeness or timeliness of the information available on this site. The content of this site is provided for informational purposes only and should not be relied upon as your sole source of information for making decisions without first consulting more accurate, complete and up-to-date information sources. If you decide to rely on the content presented on this site, you do so at your own risk.\n
    This site may contain certain prior information. Such past information, by its nature, is not current and is provided for informational purposes only. We reserve the right to change the content of this site at any time, but we have no obligation to update the information on our site. You agree that it is your responsibility to monitor changes to our site.\n`
  },
  {
    title: "ARTICLE 4 - CHANGES IN SERVICE AND PRICES",
    text: `Prices for our products are subject to change without notice.\n
    We reserve the right at any time to modify or discontinue the Service (or any portion or content of the Service) without notice and at any time.\n
    We will not be liable to you or any third party for any change in price, suspension or discontinuance of the Service.\n`
  },
  {
    title: "ARTICLE 5 - PRODUCTS OR SERVICES (if applicable)",
    text: `Certain products or services may be available exclusively online through our website. These products or services may be available in limited quantities and may only be returned or exchanged in accordance with our Return Policy.\n
    We have done our best to display as clearly as possible the colors and images of our products that appear in our store. We cannot guarantee that your computer screen will display the colors accurately.\n
    We reserve the right, but are not obligated, to limit sales of our products or services to any person, and in any geographic area or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any product or service we offer. All product descriptions and prices are subject to change at any time without notice, at our sole discretion. We reserve the right to discontinue offering any product at any time. Any offer of service or product made on this site is void where prohibited by law.\n
    We do not warrant that the quality of any products, services, information, or other merchandise obtained or purchased by you will meet your expectations, or that any errors in the Service will be corrected.\n`
  },
  {
    title: "SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION",
    text: `We reserve the right to refuse any order you place with us. We may, in our sole discretion, reduce or cancel quantities purchased per person, per household or per order. These restrictions could include orders placed by or from the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we change an order or cancel an order, we may attempt to notify you by contacting you at the email and/or billing address/phone number provided at the time the order was placed. We reserve the right to limit or prohibit orders that, in our sole judgment, may appear to be from merchants, resellers or distributors.\n
    You agree to provide current, complete and accurate order and account information for all orders placed on our store. You agree to promptly update your account and other information, including your email address, credit card numbers and expiration dates, so that we may complete your transactions and contact you if necessary.\n`
  },
  {
    title: "SECTION 7 - OPTIONAL TOOLS",
    text: `We may provide you with access to third party tools over which we have no control or influence.\n
    You acknowledge and agree that we provide access to such tools on an "as is" and "as available" basis, without any warranties, representations or conditions of any kind and without any endorsement. We will have no legal liability arising from or related to the use of these optional third-party tools.\n
    If you use any of the optional tools offered on the Site, you do so at your own risk and discretion, and you should consult the terms and conditions under which such tools are offered by the relevant third party provider(s).\n
    We may also, in the future, offer new services and/or features on our Site (including new tools and resources). These new features and services will also be subject to these Terms and Conditions of Use.\n`
  },
  {
    title: "SECTION 8 - THIRD PARTY LINKS",
    text: `Some content, products and services available through our Service may include materials from third parties.\n
    Third party links on this site may direct you to third party websites that are not affiliated with us. We are not required to review or evaluate the content or accuracy of these sites, and we do not warrant or assume any responsibility for any content, website, products, services or other items accessible on or from such third party sites.\n
    We are not responsible for any harm or damage related to the purchase or use of any goods, services, resources, content, or any other transactions conducted in connection with such third-party websites. Please read the policies and practices of third parties carefully and make sure you understand them before engaging in any transactions. Complaints, claims, concerns, or questions regarding these third party products should be submitted to the third party.\n`
  },
  {
    title: "SECTION 9 - USER COMMENTS, SUGGESTIONS AND OTHER SUBMISSIONS",
    text: `If, at our request, you submit specific content (e.g., to enter contests), or if, without our request, you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by e-mail, by mail, or otherwise (collectively, "Comments"), you grant us the unrestricted right at all times to edit, copy, publish, distribute, translate, and otherwise use in any media any Comments you send to us. We are not and shall not be obligated to (1) maintain the confidentiality of any Comments; (2) pay compensation to anyone for any Comments provided; or (3) respond to any Comments.\n
    We may, but have no obligation to, monitor, edit or remove content that we believe, in our sole discretion, is unlawful, offensive, threatening, abusive, defamatory, pornographic, obscene or otherwise objectionable, or that infringes any intellectual property or these Terms and Conditions.\n
    You agree to write comments that do not violate the rights of third parties, including copyrights, trademarks, privacy, personality, or other personal or proprietary rights. You also agree that your comments will not contain any unlawful, libelous, defamatory, offensive or obscene material, nor will they contain computer viruses or other malicious software that could in any way affect the operation of the Service or any associated website. You may not use a false email address, pretend to be someone you are not, or attempt to mislead us and/or third parties as to the origin of your comments. You are solely responsible for all comments you post and their accuracy. We take no responsibility and assume no liability for any comments you post or that any other third party posts.\n`
  },
  {
    title: "SECTION 10 - PERSONAL INFORMATION",
    text: `The submission of your personal information on our store is governed by our Privacy Policy. Click here to view our Privacy Policy.`
  },
  {
    title: "SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS",
    text: `From time to time, there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, prices, promotions, offers, product shipping costs, delivery times and availability. We reserve the right to correct any errors, inaccuracies, omissions, and to change or update information or cancel orders, if any information on the Service or any associated website is inaccurate, at any time without notice (including after you have placed your order).\n
    We are under no obligation to update, modify or clarify any information on the Service or any associated website, including but not limited to pricing information, except as required by law. No defined update or refresh date in the Service or any associated website should be taken as a basis for concluding that the information in the Service or any associated website has been changed or updated.\n`
  },
  {
    title: "ARTICLE 12 - PROHIBITED USES",
    text: `In addition to the prohibitions set forth in the Terms and Conditions of Use, you are prohibited from using the Site or its contents: (a) for illegal purposes; (b) to induce others to perform or participate in illegal acts; (c) to violate any regional ordinance or any international, federal, provincial or state law, rule or regulation; (d) to infringe or violate our intellectual property rights or those of third parties; (e) harass, abuse, insult, hurt, defame, slander, libel, disparage, intimidate or discriminate against anyone on the basis of gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) submit false or misleading information (g) to upload or transmit viruses or any other type of malicious code that will or may be used to compromise the functionality or operation of the Service or any associated, unrelated website or the Internet; (h) to collect or track the personal information of others (i) to spam, phish, hijack, extort information, browse, explore or scan the web (or any other resource); (j) for obscene or immoral purposes; or (k) to interfere with or circumvent security measures of our Service, any other website, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating the prohibited uses.`
  },
  {
    title: "SECTION 13 - DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY",
    text: `We do not warrant or represent that your use of our Service will be uninterrupted, timely, secure or error-free.\n
    We do not warrant that the results that may be obtained through the use of the Service will be accurate or reliable.\n
    You agree that from time to time we may discontinue the Service for indefinite periods of time or cancel the Service at any time without notice to you.\n
    You expressly agree that your use of, or inability to use, the Service is at your sole risk. The Service and all products and services provided to you through the Service are (unless otherwise expressly stated by us) provided on an "as is" and "as available" basis for your use without representation, warranty or condition of any kind, either express or implied, including all implied warranties of merchantability or fitness for a particular purpose, durability, title and non-infringement.\n
    In no event shall Cohackers, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, and licensors be liable for any injury, loss, claim, or direct, indirect, incidental, punitive, special, or consequential damages of any kind, including but not limited to loss of profits, revenue, savings, data, replacement costs, or similar damages, whether in contract, tort (including negligence), in contract, tort (including negligence), strict liability or otherwise, arising out of your use of any service or product from the Service, or for any other claim relating in any way to your use of the Service or any product, including but not limited to any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the Service or any content (or product) posted, transmitted, or otherwise made available through the Service, even if you have been advised of the possibility of such claims arising. Because some states or jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, our liability will be limited to the maximum extent permitted by law.\n`
  },
  {
    title: "SECTION 14 - INDEMNIFICATION",
    text: `You agree to indemnify, defend and hold Cohackers, our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms and Conditions or the documents referenced herein, or your violation of any law or the rights of another.`
  },
  {
    title: "SECTION 15 - SEVERABILITY",
    text: `In the event that any provision of these Terms and Conditions of Sale and Use is held to be unlawful, void or unenforceable, such provision shall nonetheless be enforced to the fullest extent permitted by law, and the unenforceable portion shall be deemed severed from these Terms and Conditions of Sale and Use, such severance not to affect the validity and enforceability of any remaining provisions.`
  },
  {
    title: "SECTION 16 - TERMINATION",
    text: `The obligations and liabilities incurred by the parties prior to the date of termination shall survive the termination of this Agreement for all purposes.\n
    These Terms and Conditions of Sale and Use are effective unless and until terminated by either you or not. You may terminate these Terms and Conditions of Sale and Use at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.\n
    If we determine, in our sole discretion, that you are unsuccessful, or if we suspect that you have been unable to comply with the terms of these Terms of Sale and Use, we may also terminate this agreement at any time without notice to you and you will remain liable for all amounts owing up to and including the date of termination, and/or we may deny you access to our Services (or any portion thereof).\n
    `
  },
  {
    title: "SECTION 17 - ENTIRE AGREEMENT",
    text: `Any failure by us to exercise or enforce any right or provision of these Terms and Conditions of Sale and Use shall not constitute a waiver of such right or provision.\n
    These Terms of Service or any other operating policies or rules posted by us on this site or in connection with the Service constitute the entire agreement and understanding between you and us and govern your use of the Service, and supersede all prior and contemporaneous communications, proposals and agreements, oral or written, between you and us (including, but not limited to, any prior version of the Terms of Service).\n
    Any ambiguity in the interpretation of these Terms and Conditions shall not be construed to the detriment of the drafting party.\n`
  },
  {
    title: "SECTION 18 - GOVERNING LAW",
    text: `These Terms and Conditions of Service and any separate agreements through which we provide you Services shall be governed by and construed in accordance with the laws of France.`
  },
  {
    title:
      "ARTICLE 19 - CHANGES TO THE GENERAL TERMS AND CONDITIONS OF SALE AND USE",
    text: `You may review the most current version of the Terms of Sale and Use at any time on this page.\n
    We reserve the right, at our sole discretion, to update, modify or replace any part of these Terms and Conditions of Sale and Use by posting updates and changes on our site. It is your responsibility to visit our site regularly to check for changes. Your continued use of or access to our site following the posting of any changes to these Terms and Conditions of Sale and Use constitutes acceptance of those changes.\n
    `
  },
  {
    title: "SECTION 20 - CONTACT INFORMATION",
    text: `Questions regarding the Terms of Sale and Use should be sent to us at privacy@cohackers.co .`
  }
];
