<template>
  <div class="container is-fluid">
    <h1 class="title margin-t">GENERAL CONDITIONS OF SALE AND USE</h1>
    <hr />
    <div v-for="{ title, text } in gtc" :key="title">
      <h6 class="title is-6">{{ title }}</h6>
      <p style="white-space: pre-line">{{ text }}</p>
      <br />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { gtc } from "../constants/gtc";

export default defineComponent({
  setup() {
    onMounted(() => {
      window.scrollTo({ top: 0 });
    });

    return {
      gtc
    };
  }
});
</script>

<style>
.margin-t {
  margin-top: 50px;
}
</style>
